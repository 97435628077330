@import "../../../scss/index.scss";

.sign-in {
  //background-image: url("../../../assets/img/png/Home.png");
  background-color: #e9ebee;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &__footer {
    background-color: rgb(173, 213, 44);
    color: rgb(30, 40, 82);
    font-weight: bold;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 70px);
    overflow: auto;

    &-logo {
      padding: 0px 20px;
      img {
        width: 100%;
        max-width: 300px;
        //margin-left: 10%;
      }
    }

    &-subtitle {
      padding-bottom: 10px;
      font-size: 0em + 22px / $defaultFontSize;
      color: white;
    }

    &-tabs {
      background-color: $background-light;
      margin-bottom: 60px;

      .ant-tabs {
        width: 60vw;

        @media (min-width: $media-breackpoint-up-lg) {
          width: 25vw;
        }
      }

      .ant-tabs-nav-container {
        height: 60px !important;
      }

      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #f44336;
      }

      .ant-tabs-nav-list {
        width: 100%;
      }

      .ant-tabs-tab {
        width: 50%;
        height: 60px !important;
        text-align: center;
        font-size: 0em + 16px / $defaultFontSize;
        border-top: 4px solid $border-grey !important;
        border-radius: 0px !important;
        margin: 0 !important;

        @media (min-width: $media-breackpoint-up-lg) {
          width: 240px;
          font-size: 0em + 18px / $defaultFontSize;
        }

        &:hover {
          color: $font-grey-dark;
        }

        &::before {
          content: none;
        }

        span {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-tabs-tab-btn {
        width: 100%;
      }

      .ant-tabs-tab-active {
        color: $primary-color !important;
        border-top: 4px solid $primary-color !important;
        border-radius: 0 !important;
        border-right: 0 !important;
        border-left: 0 !important;
      }

      .ant-tabs-content {
        padding: 0 16px 16px 16px;
        text-align: center;
      }
    }
  }
}
