@import "../scss/index.scss";

.layoutbasic {
  //   min-height: 100vh;
  &__footer {
    //border-top: 1px solid rgb(241, 241, 241);
    background-color: #fff;
    color: rgb(30, 40, 82);
    //font-weight: bold;
    text-align: right;
  }
}
