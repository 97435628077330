@import "../../../scss/index.scss";
.menu-top {
  display: flex;
  //background-color: $background-tigerlily;

  button {
    color: $font-dark-light;
    &:hover {
      color: $primary-color;
    }
    &focus,
    &active {
      color: $font-light;
    }
  }

  &__left {
    //margin-top: -25px;

    &-logo {
      width: 120px;
      padding: 0px 10px;
    }
  }

  &__right {
    position: absolute;
    right: 0;
  }
}
