@import "./scss/index.scss";

.success {
  border: 1px solid $success;
  background-color: $background-success;
}

.error {
  border: 1px solid $error;
  background-color: $background-error;
}

header {
  z-index: 99;
}

.color-1 {
  background-color: #175e92;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-2 {
  background-color: #f8bf32;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-3 {
  background-color: #fdf734;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-4 {
  background-color: #92d050;
  width: 120px;
  text-align: center;
  font-weight: bold;
}
.color-5 {
  background-color: #8eaadb;
  width: 120px;
  text-align: center;
  font-weight: bold;
}

.colBorder {
  border-bottom: 1px solid gray;
}

.margin-right-minimun {
  margin-right: 2px;
}

.margin-bottom-minimun {
  margin-bottom: 5px;
}

.hide {
  display: none;
}

.text-left {
  text-align: left;
}

.text-rigth {
  text-align: right;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $primary-color;
}

::selection {
  background: $primary-color;
}

.ant-menu-item-selected {
  color: $primary-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $primary-color-light;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $primary-color;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: $primary-color;
}

.ant-menu-item a:hover {
  color: $primary-color;
}

.ant-menu-submenu-selected {
  color: $primary-color;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: $primary-color-dark;
  background: $primary-color-dark;
}

.ant-btn-primary {
  color: #fff;
  border-color: $primary-color;
  background: $primary-color;
}

.btnBig {
  border-radius: 24px;
  padding: 10px 20px;
  font-size: 16px;
  height: 50px;
}

.ant-btn {
  border-radius: 6px;
}

// .smallBtn {
//   padding: 2px 2px !important;
//   height: 20px !important;
// }

.mini {
  border-radius: 12px !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  height: 30px !important;
  margin-left: 10px !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: $primary-color;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: rgb(173, 213, 44);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: rgb(173, 213, 44);
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: $primary-color;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: rgb(30, 40, 82);
}

.ant-result-success .ant-result-icon > .anticon {
  color: rgb(173, 213, 44);
}

.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: rgb(30, 40, 82);
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
}

.ant-input-number {
  width: 100%;
}

// .ant-form-vertical .ant-form-item-label,
// .ant-col-24.ant-form-item-label,
// .ant-col-xl-24.ant-form-item-label {
//   font-size: 12px !important;
//   font-weight: bold;
// }

.ant-form-item-label > label {
  font-size: 12px !important;
  font-weight: bold;
}

.ant-page-header-heading-title {
  font-weight: normal !important;
}
