@import "../scss/index.scss";

.eventos {
  min-height: calc(100vh - 70px);
  //background-image: url("../assets/img/jpg/fondo.jpg");
  // background-repeat: no-repeat;
  // background-position: center right;
  // background-size: cover;
  // background-attachment: fixed;
  background-color: #e9ebee;

  &__header {
    //background-color: #e9ebee;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
    height: 90px;
  }
  &__content {
    padding: 2% 2%;
    &__steps {
      padding: 5%;
      background-color: white;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }
  }
  &__finish {
    padding: 2% 2%;
    //display: none;
    &__steps {
      padding: 5%;
      background-color: white;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }
  }
}

.ant-page-header-heading {
  justify-content: center;
}

.left .ant-page-header-heading {
  justify-content: left !important;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px;
}

.ant-steps-item-title {
  font-size: 13px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.bakcground-video-image {
  background: url("../assets/img/jpg/back.jpg") no-repeat;
  background-position: center;
  background-size: 50%;
  background-color: white;
}

.background-video {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 0%;
  //height: 100%;
}
.overlay-video {
  // position: absolute;
  // top: 75px;
  // left: 25px;
  //width: 160px;
  //height: 160px;
  width: 100%;
  //border: 3px solid #e9ebee;
}

.overlay-video {
  //z-index: 1; /* Asegura que el video de la capa superior esté encima del video de la capa inferior */
}

.control-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.control-buttons button {
  margin-left: 5px;
}
